import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionAlertInfo,
  ActionType,
  EmailNotificationRecipient,
  MemberPermissionData,
  NotificationsType,
  PowerAction,
  Question,
  QuestionFieldIcon,
  QuestionFieldType,
  RecipientInfo,
  StationBucketQuestion,
  StationFieldPrefix,
  TeamPermissionData,
  UserAssociateType,
} from 'src/models';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Observable, Subject, first, map, of, take, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AdvancedSearchModalComponent } from 'src/app/search/advanced-search-modal/advanced-search-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EmailSubjectModalComponent } from 'src/app/station/rules/actions/notifications/email-subject-modal/email-subject-modal.component';
import { PopupService } from 'src/app/core/popup.service';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { OrganizationService } from 'src/app/core/organization.service';
import { StationService } from 'src/app/core/station.service';
import { EmailValidator, StationTerms } from 'src/helpers';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';

/** Email notification form. */
@Component({
  selector: 'app-email-notifications-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingIndicatorComponent,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatButtonModule,
    UserAvatarComponent,
    LoadingIndicatorComponent,
  ],
  templateUrl: './email-notifications-form.component.html',
  styleUrls: ['./email-notifications-form.component.scss'],
})
export class EmailNotificationsFormComponent implements OnInit {
  /** Subject that emits when the component has been destroyed. */
  protected _destroyed$ = new Subject<void>();

  /** Recipient input html. */
  @ViewChild('recipientInput') recipientInput!: ElementRef<HTMLInputElement>;

  /** Separator keys. */
  separatorKeysCodes: number[] = [ENTER, COMMA];

  /** Contains the action of the power that will be edited. */
  private _actionToUpdate: PowerAction = {
    order: 1,
    rithmId: '',
    type: ActionType.SendEmail,
    target: '',
    data: '',
    resultMapping: '',
    header: '',
  };

  /** Contains the action of the power that will be edited. */
  @Input() set action(value: PowerAction | null) {
    if (value) {
      this._actionToUpdate = value;
      this.editMode = true;
    } else {
      this._actionToUpdate.rithmId = uuidv4();
    }
  }

  /** Loading the custom fields on the station. */
  @Input() customFieldsLoading = false;

  /** Error the custom fields on the station. */
  @Input() customFieldsError = false;

  /** The station id used to get shared values. */
  @Input() set stationRithmId(stationRithmId: string) {
    if (stationRithmId) {
      this._stationRithmId = stationRithmId;
      this.loadMembers();
    }
  }

  /** All custom fields. */
  @Input() set allRecipientsCustomField(value: StationBucketQuestion[]) {
    this._allRecipientsCustomField = value;
    this.emailFields = value.filter(
      (q) => q.questionType === QuestionFieldType.Email,
    );
    if (this.editMode) {
      this.setEditFormValues();
    }
  }

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Whether you are saving the current integration in parent. */
  @Input() savingNotification = false;

  /** Whether you are deleting the current integration in parent. */
  @Input() deletingNotification = false;

  /** Emit the new action to add. */
  @Output() notificationPowerEmitter = new EventEmitter<PowerAction>();

  /** Emit event to cancel. */
  @Output() cancelFormEmitter = new EventEmitter();

  /** Emit the current notification to delete it. */
  @Output() notificationToRemove = new EventEmitter<PowerAction>();

  /** Id of the current station. */
  private _stationRithmId = '';

  /** All recipient fields. */
  private _allRecipientsCustomField: StationBucketQuestion[] = [];

  /** Email custom fields. */
  emailFields: StationBucketQuestion[] = [];

  /**
   * Get the recipient fields.
   * @returns The list of recipient fields.
   */
  get allRecipientsCustomField(): StationBucketQuestion[] {
    return this._allRecipientsCustomField;
  }

  /**
   * Get the station rithmId.
   * @returns An id.
   */
  get stationRithmId(): string {
    return this._stationRithmId;
  }

  /** Recipient form control. */
  recipientControl = new FormControl('', {
    validators: [Validators.required, EmailValidator.validateEmail()],
  });

  /** Subject form control. */
  subjectControl = new FormControl('', {
    validators: [Validators.required],
  });

  /** Edit mode. */
  editMode = false;

  /** Notification action form. */
  notificationActionForm = new FormGroup({
    recipients: new FormControl<EmailNotificationRecipient[]>([], {
      nonNullable: true,
    }),
    subject: new FormControl<(StationBucketQuestion | string)[]>([], {
      nonNullable: true,
    }),
    message: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  /** Filtered recipients. */
  filteredMemberRecipients$!: Observable<MemberPermissionData[]>;

  /** Filtered recipients teams. */
  filteredRecipientsTeams$!: Observable<MemberPermissionData[]>;

  /** Filtered recipients custom fields. */
  filteredRecipientsCustomField$!: Observable<StationBucketQuestion[]>;

  /* Comment: Owners and roster members */
  allRecipientsMembers: MemberPermissionData[] = [];

  /** All recipients team. */
  allRecipientsTeams: MemberPermissionData[] = [];

  /** Member list loading. */
  memberListLoading = false;

  /** Error Get Members. */
  memberListError = false;

  /** Team List loading. */
  teamListLoading = false;

  /** Team List error. */
  teamListError = false;

  /** Custom recipient array. */
  recipientTo: string[] = [];

  /**
   * Whether the recipients field is invalid.
   * @returns If the recipients field is invalid.
   */
  get isRecipientsFieldInvalid(): boolean {
    return (
      this.recipientControl.touched &&
      this.recipientControl.invalid &&
      this.notificationActionForm.value.recipients?.length === 0
    );
  }

  /**
   * Whether the subject field is invalid.
   * @returns If the subject field is invalid.
   */
  get isSubjectFieldInvalid(): boolean {
    return (
      this.subjectControl.touched &&
      this.subjectControl.invalid &&
      this.notificationActionForm.value.subject?.length === 0
    );
  }

  /**
   * Get the container info list.
   * @returns The types of the container info items.
   */
  get containerInfoList(): QuestionFieldType[] {
    return StationTerms.ContainerInfoItems.map(
      ({ questionType }) => questionType,
    ) as QuestionFieldType[];
  }

  constructor(
    public dialog: MatDialog,
    private popupService: PopupService,
    private stationService: StationService,
    private organizationService: OrganizationService,
  ) {
    this.recipientControl.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        this.filteredMemberRecipients$ = of(
          this._filterAllMembers(value?.toString() || ''),
        );
        this.filteredRecipientsTeams$ = of(
          this._filterAllTeams(value?.toString() || ''),
        );
        this.filteredRecipientsCustomField$ = of(
          this._filterAllCustomFields(value?.toString() || ''),
        );
      });
  }

  /**
   * Initialization of the component.
   */
  ngOnInit(): void {
    this.filteredRecipientsCustomField$ = of(this.emailFields);
  }

  /**
   * Set edit form values.
   */
  setEditFormValues(): void {
    const data = JSON.parse(this._actionToUpdate.data) as ActionAlertInfo;
    data.subject?.forEach((subject, index) => {
      const existSubject = this._allRecipientsCustomField.some(
        (field) => field.rithmId === subject,
      );
      !existSubject && data.subject?.splice(index, 1);
    });

    if (!('recipients' in data)) return;
    let recipients = Object.keys(data.recipients).reduce((list, key) => {
      const members = (
        data.recipients as unknown as {
          [key: string]: EmailNotificationRecipient[];
        }
      )[key];
      if (members.length) {
        return list.concat(members);
      }
      return list;
    }, [] as EmailNotificationRecipient[]);
    const emailRecipients = data.to.map((recipient) => {
      const isCustomField = recipient.includes(StationFieldPrefix.CustomField);
      if (isCustomField) {
        const recipientId = recipient.replace(
          StationFieldPrefix.CustomField,
          '',
        );

        return (
          this.emailFields.find(({ rithmId }) => rithmId === recipientId) ||
          recipient
        );
      }

      return recipient;
    });
    recipients = recipients.concat(emailRecipients);

    const subject =
      data.subject?.map((item) => {
        const isContainerInfoField = item.includes(
          StationFieldPrefix.ContainerInfoItem,
        );
        const isCustomField = item.includes(StationFieldPrefix.CustomField);
        if (!isCustomField && !isContainerInfoField) {
          return item;
        }

        const fieldId = isCustomField
          ? item.replace(StationFieldPrefix.CustomField, '')
          : item.replace(StationFieldPrefix.ContainerInfoItem, '');
        const field =
          this.allRecipientsCustomField.find(
            ({ rithmId, questionType }) =>
              rithmId === fieldId || questionType === fieldId,
          ) || item;
        return field;
      }) || [];

    this.notificationActionForm.patchValue({
      recipients,
      subject,
      message: data.message,
    });
  }

  /**
   * Select a recipient.
   * @param recipientSelected Recipient selected.
   */
  selected(recipientSelected: EmailNotificationRecipient): void {
    const isAlreadySelected = this.alreadySelected(recipientSelected);

    if (!isAlreadySelected) {
      this.notificationActionForm.value.recipients?.push(recipientSelected);
    }

    this.cleanAutoComplete();
  }

  /**
   * Handle subject according to its type.
   * @returns A string array.
   */
  processSubject(): string[] {
    return this.notificationActionForm.getRawValue().subject.map((item) => {
      if (typeof item === 'string') return item;
      const isContainerInfoField = this.containerInfoList.includes(
        item.questionType as QuestionFieldType,
      );
      return isContainerInfoField
        ? `${StationFieldPrefix.ContainerInfoItem}${item.questionType}`
        : `${StationFieldPrefix.CustomField}${item.rithmId}`;
    });
  }

  /**
   * Manipulate the form's containers and split them into their related property.
   * @returns An array of recipients.
   */
  processRecipients(): RecipientInfo {
    const recipient = {
      organizations: [],
      teams: [],
      stationGroups: [],
      stations: [],
      users: [],
    } as RecipientInfo;

    this.notificationActionForm.getRawValue().recipients.forEach((m) => {
      /** Recipient - Organization, Station, StationGroups, etc.. */
      if (this.hasProperty(m, 'type')) {
        this.processMemberPermissionData(m as MemberPermissionData, recipient);
      }

      /** To - Station Bucket. */
      if (this.hasProperty(m, 'questionType')) {
        this.recipientTo.push(
          `${StationFieldPrefix.CustomField}${this.getRecipientValue(m)}`,
        );
      }

      /** To - plain text. */
      if (typeof m === 'string') {
        this.recipientTo.push(m);
      }
    });

    return recipient;
  }

  /**
   * Checks if it contains a specific property within the array.
   * @param obj Selected object.
   * @param prop Specific property.
   * @returns A boolean.
   */
  hasProperty(obj: EmailNotificationRecipient, prop: string): boolean {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }

  /**
   * Parse an object of type team to member.
   * @param value Selected value.
   * @param type Selected type.
   * @returns An object of member.
   */
  parseValueToMember(
    value: TeamPermissionData,
    type: UserAssociateType,
  ): MemberPermissionData {
    return {
      rithmId: value.rithmId,
      profileImageRithmId: value.photoVaultId,
      firstName: value.name,
      lastName: '',
      email: '',
      name: value.name,
      type: type,
    };
  }

  /**
   * Organize recipients according to the corresponding type of members.
   * @param m Selected member.
   * @param recipient Recipients object.
   */
  processMemberPermissionData(
    m: MemberPermissionData,
    recipient: RecipientInfo,
  ): void {
    switch (m.type) {
      case UserAssociateType.Organization:
        recipient.organizations.push(m);
        break;

      case UserAssociateType.Station:
        recipient.stations.push(m);
        break;

      case UserAssociateType.StationGroup:
        recipient.stationGroups.push(m);
        break;

      case UserAssociateType.User:
        recipient.users.push(m);
        break;

      case UserAssociateType.Team:
        recipient.teams.push(m);
        break;

      default:
        break;
    }
  }

  /**
   * To know if recipient is already selected.
   * @param recipient Recipient to check.
   * @returns  If the recipient is already selected.
   */
  alreadySelected(recipient: EmailNotificationRecipient): boolean {
    const recipientValueSelected = this.getRecipientValue(recipient);

    return !!this.notificationActionForm.value.recipients?.some(
      (r) => this.getRecipientValue(r) === recipientValueSelected,
    );
  }

  /**
   * Remove recipient.
   * @param recipientToRemove Recipient to remove.
   */
  removeRecipient(recipientToRemove: EmailNotificationRecipient): void {
    const recipientIndex =
      this.notificationActionForm.value.recipients?.indexOf(recipientToRemove);
    if (recipientIndex !== undefined && recipientIndex >= 0) {
      this.notificationActionForm.value.recipients?.splice(recipientIndex, 1);
    }
    this.cleanAutoComplete();
  }

  /**
   * Clean Auto complete.
   */
  cleanAutoComplete(): void {
    this.recipientInput.nativeElement.value = '';
    this.recipientControl.setValue(null);
  }

  /**
   * Filter all members.
   * @param value Member to filter.
   * @returns Filtered list according value.
   */
  private _filterAllMembers(value: string): MemberPermissionData[] {
    const filterValue = value.toLowerCase();
    return (
      this.allRecipientsMembers?.filter(
        (recipientMember) =>
          recipientMember.firstName.toLowerCase().includes(filterValue) ||
          recipientMember.lastName.toLowerCase().includes(filterValue),
      ) || []
    );
  }

  /**
   * Filter all teams.
   * @param value Member to filter.
   * @returns Filtered list according value.
   */
  private _filterAllTeams(value: string): MemberPermissionData[] {
    const filterValue = value.toLowerCase();
    return (
      (this.allRecipientsTeams?.filter((recipientTeam) =>
        recipientTeam.name.toLowerCase().includes(filterValue),
      ) as MemberPermissionData[]) || []
    );
  }

  /**
   * Filter all fields.
   * @param value Member to filter.
   * @returns Filtered list according value.
   */
  private _filterAllCustomFields(value: string): StationBucketQuestion[] {
    const filterValue = value.toLowerCase();
    return (
      this.emailFields?.filter((recipient) =>
        recipient.prompt.toLowerCase().includes(filterValue),
      ) || []
    );
  }

  /**
   * Disable notification.
   * @returns If disabled notification.
   */
  disableAddNotification(): boolean {
    return (
      this.notificationActionForm.invalid ||
      !this.notificationActionForm.getRawValue().recipients?.length ||
      !this.notificationActionForm.getRawValue().subject?.length
    );
  }

  /**
   * Open advanced search modal.
   */
  openAdvancedSearchModal(): void {
    const dialog = this.dialog.open(AdvancedSearchModalComponent, {
      disableClose: true,
      panelClass: ['w-11/12', 'md:w-4/6', 'lg:w-2/4', 'h-'],
      maxWidth: '600px',
      autoFocus: false,
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (
          recipients: (MemberPermissionData | StationBucketQuestion)[],
        ) => {
          if (recipients?.length) {
            recipients.map((recipient) => {
              const isAlreadySelected =
                this.notificationActionForm.value.recipients?.some(
                  (recipientData) =>
                    this.getRecipientValue(recipientData) === recipient.rithmId,
                );

              if (!isAlreadySelected) {
                this.notificationActionForm.value.recipients?.push(recipient);
              }
            });
          }
        },
      });
  }

  /**
   * Open advanced search modal.
   */
  openEmailSubjectModal(): void {
    const subjectDialog = this.dialog.open(EmailSubjectModalComponent, {
      disableClose: true,
      panelClass: ['w-full', 'md:w-[768px]', 'h-[588px]'],
      autoFocus: false,
      data: {
        stationRithmId: this.stationRithmId,
      },
    });

    subjectDialog
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (data: StationBucketQuestion) => {
          if (!data || typeof data !== 'object') return;
          const isAlreadySelected =
            this.notificationActionForm.value.subject?.some(
              (subjectData) =>
                this.getRecipientValue(subjectData) === data.rithmId,
            );

          if (!isAlreadySelected) {
            this.notificationActionForm.value.subject?.push(data);
          }
        },
      });
  }

  /**
   * Saving action type changes.
   */
  public addNotificationAction(): void {
    const recipients = this.processRecipients();
    const actionAlertInfo: ActionAlertInfo = {
      createdByRithmId: '',
      subject: this.processSubject(),
      message: this.notificationActionForm.getRawValue().message,
      to: this.recipientTo,
      types: [NotificationsType.Email],
      recipients: recipients,
    };
    const createdOrUpdatedAction: PowerAction = {
      order: this._actionToUpdate ? this._actionToUpdate.order : 0,
      rithmId: this.editMode ? this._actionToUpdate.rithmId : uuidv4(),
      type: ActionType.SendEmail,
      target: this.stationRithmId,
      data: JSON.stringify(actionAlertInfo),
      resultMapping: '',
      header: '',
    };

    this.notificationPowerEmitter.emit(createdOrUpdatedAction);
  }

  /**
   * Add subject to chips.
   * @param event Event.
   */
  public addSubject(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.notificationActionForm.value.subject?.push(value);
    }
    this.subjectControl.setValue('');
  }

  /**
   * Remove subject to chips.
   * @param subject Subject selected.
   */
  public removeSubject(subject: StationBucketQuestion | string): void {
    const subjectIndex =
      this.notificationActionForm.value.subject?.indexOf(subject);
    if (subjectIndex !== undefined && subjectIndex >= 0) {
      this.notificationActionForm.value.subject?.splice(subjectIndex, 1);
    }
  }

  /**
   * Initiate a confirmation popup for cancel changes in editMode.
   */
  async confirmCancel(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Cancel?',
      message: 'Any unsaved progress will be lost',
      okButtonText: 'Yes, Cancel',
      cancelButtonText: 'Back',
      important: true,
    });
    if (response) {
      this.cancelFormEmitter.emit();
    }
  }

  /**
   * Get the list of members.
   * @param searchString Search string.
   */
  getRecipientMemberList(searchString = ''): void {
    this.memberListLoading = true;
    const pageNumber = 1;
    const pageSize = 100;
    const roleRithmId = '';

    this.filteredMemberRecipients$ = this.stationService.getStationMembers(
      this.stationRithmId,
      roleRithmId,
      pageNumber,
      pageSize,
      searchString,
    );
    this.filteredMemberRecipients$.pipe(first()).subscribe({
      next: (members) => {
        this.allRecipientsMembers = members;
        this.memberListLoading = false;
      },
      error: () => {
        this.memberListError = true;
        this.memberListLoading = false;
      },
    });
  }

  /**
   * Get the list of teams.
   * @param searchString Search string.
   */
  getOrganizationTeams(searchString = ''): void {
    this.memberListLoading = true;
    const pageNumber = 1;
    const pageSize = 100;

    this.filteredRecipientsTeams$ = this.organizationService
      .getOrganizationTeams(pageNumber, pageSize, searchString)
      .pipe(
        take(1),
        map((teams: TeamPermissionData[]) =>
          teams.map((t) => this.parseValueToMember(t, UserAssociateType.Team)),
        ),
      );

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.filteredRecipientsTeams$.subscribe({
      next: (parsedTeams) => {
        this.allRecipientsTeams = parsedTeams;
        this.memberListLoading = false;
        this.teamListError = false;
      },
      error: () => {
        this.teamListError = true;
        this.memberListLoading = false;
      },
    });
  }

  /**
   * Get the name of a given recipient.
   * @param value Recipient.
   * @returns Recipient's name.
   */
  getRecipientName(value: EmailNotificationRecipient): string {
    return (
      (value as MemberPermissionData).name ||
      (value as StationBucketQuestion).prompt ||
      (value as string)
    );
  }

  /**
   * Get the rithmId or value of a given recipient.
   * @param value Recipient.
   * @returns Recipient's value.
   */
  getRecipientValue(value: EmailNotificationRecipient): string {
    return (
      (value as StationBucketQuestion).rithmId ||
      (value as MemberPermissionData).rithmId ||
      (value as string)
    );
  }

  /**
   * Check whether it should show an icon or not.
   * @param option Option selected.
   * @returns A boolean.
   */
  showIcon(option: MemberPermissionData): boolean {
    return (
      !option.profileImageRithmId && option.type !== UserAssociateType.User
    );
  }

  /**
   * Get the associated icon class for the recipient.
   * @param recipient The recipient.
   * @returns The icon class.
   */
  getRecipientIcon(recipient: EmailNotificationRecipient): string {
    const defaultClass = 'fal fa-at text-sky-500 text-sm';

    if (typeof recipient !== 'object') return defaultClass;
    if (!('type' in recipient)) return '';

    return recipient.type === UserAssociateType.Organization
      ? 'fa-thin fa-globe'
      : recipient.type === UserAssociateType.Team
        ? 'fal fa-users'
        : recipient.type === UserAssociateType.Station
          ? 'fak fa-station'
          : recipient.type === UserAssociateType.StationGroup
            ? 'fas fa-grid-2'
            : '';
  }

  /**
   * Get icon for a subject item.
   * @param subject The subject item.
   * @returns The icon class.
   */
  getSubjectIcon(subject: StationBucketQuestion | string | Question): string {
    if (typeof subject === 'string') return '';
    return QuestionFieldIcon[subject.questionType as QuestionFieldType];
  }

  /**
   * Load members and teams.
   */
  loadMembers(): void {
    this.getRecipientMemberList();
    this.getOrganizationTeams();
  }

  /**
   * Remove the notification from the parent.
   */
  removeParentNotification(): void {
    this.notificationToRemove.emit(this._actionToUpdate);
  }
}
