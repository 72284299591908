<div class="h-full w-full">
  <section [ngClass]="{ 'hover:!bg-white': orderOfOperations }">
    <div class="w-full overflow-x-auto scrollbar">
      <span
        [ngClass]="
          orderOfOperations ? 'action-title' : 'text-primary-400 text-sm'
        "
      >
        @if (!orderOfOperations) {
          <ng-container>Action: </ng-container>
        }
        Appended field</span
      >

      <section class="mt-4">
        <p class="sub-section-title">Base field:</p>
        <mat-chip-set>
          <mat-chip>
            @if (orderOfOperations) {
              @if (baseField) {
                <i
                  class="icon-chip"
                  [ngClass]="questionFieldIcon[baseField.questionType]"
                ></i>
              } @else {
                --
              }
              <span class="label-chip">
                {{ baseField ? baseField.prompt : '' }}
              </span>
            } @else {
              {{ baseField ? baseField.prompt : '' }}
            }
          </mat-chip>
        </mat-chip-set>
      </section>

      @if (actionData) {
        <section class="mt-2">
          <p class="sub-section-title">Appended Values:</p>
          <mat-chip-set>
            @for (item of keysField; track $index) {
              @if (orderOfOperations) {
                <mat-chip>
                  @if (appendValues[item]) {
                    <i
                      class="icon-chip"
                      [ngClass]="appendValues[item].icon"
                    ></i>
                  }
                  <span class="label-chip">
                    {{
                      appendValues[item].prompt
                        ? appendValues[item].prompt
                        : '--'
                    }}
                  </span>
                </mat-chip>
              } @else {
                <mat-chip>
                  {{
                    appendValues[item].prompt ? appendValues[item].prompt : '--'
                  }}</mat-chip
                >
              }
            }
          </mat-chip-set>
        </section>
      }
    </div>
  </section>
</div>
