import { CommonModule } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import _ from 'lodash';
import {
  ComponentHelper,
  JsonValidator,
  RithmAiActionHelper,
} from 'src/helpers';
import {
  ActionType,
  AiActionData,
  AiImageSettings,
  PowerAction,
  PowerEventAction,
  PowerEventType,
  Question,
} from 'src/models';

/**
 * Render a list of Rithm AI actions.
 */
@Component({
  selector: 'app-rithm-ai-action-list',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatMenuModule, MatButtonModule],
  templateUrl: './rithm-ai-action-list.component.html',
  styleUrl: './rithm-ai-action-list.component.scss',
})
export class RithmAiActionListComponent {
  /** Component helper. */
  componentHelper = ComponentHelper;

  /** The power event type. */
  powerEventType = PowerEventType;

  /** The order of operations flag. */
  orderOfOperations = input<boolean>(false);

  /** All the bucket questions. */
  bucketQuestions = input.required<Question[]>();

  /** The action to display. */
  action = input.required<PowerAction>();

  /** The action event. */
  eventAction = output<Omit<PowerEventAction, 'powerRithmId'>>();

  /** The action data. */
  actionData = computed(() =>
    JsonValidator.getObjectFromString<AiActionData>(this.action().data),
  );

  /** The action model label. */
  actionModelLabel = computed(() => {
    const actionModelKey = Object.keys(ActionType).find(
      (key) =>
        ActionType[key as keyof typeof ActionType] === this.action().type,
    );

    return _.startCase(actionModelKey);
  });

  /** The response field. */
  responseField = computed<Question | undefined>(() => {
    if (
      this.bucketQuestions().length &&
      this.bucketQuestions().some(
        (field) =>
          field.rithmId ===
          this.actionData().responseField?.replace('rid:', ''),
      )
    ) {
      return ComponentHelper.getQuestionByFieldReference(
        this.actionData().responseField,
        this.bucketQuestions(),
        this.action().type,
      );
    }

    return undefined;
  });

  /** The labels of the image settings. */
  imageSettingsLabels = computed<string[]>(() => {
    if (!this.actionData().imageSettings) return [];

    return Object.entries(
      this.actionData().imageSettings as AiImageSettings,
    ).map(([key, value]) => {
      switch (key) {
        case 'quality':
          return (
            RithmAiActionHelper.qualityOptions.find(
              (option) => option.value === value,
            )?.title ?? ''
          );

        case 'style':
          return (
            RithmAiActionHelper.styleOptions.find(
              (option) => option.value === value,
            )?.title ?? ''
          );

        case 'size':
          return (
            RithmAiActionHelper.sizeOptions.find(
              (option) => option.value === value,
            )?.title ?? ''
          );

        default:
          return '';
      }
    });
  });

  /**
   * Emit the action event.
   * @param eventAction The event action.
   */
  emitActionEvent(eventAction: PowerEventType): void {
    this.eventAction.emit({
      action: this.action(),
      eventAction,
    });
  }
}
