import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import {
  ActionType,
  AppendActionData,
  PowerAction,
  Question,
  QuestionFieldType,
} from 'src/models';
import { ActionHelper, ConditionHelper, StationTerms } from 'src/helpers';
import { v4 as uuidv4 } from 'uuid';
import lodash from 'lodash';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatDividerModule } from '@angular/material/divider';
import { AppendedFieldComponent } from './appended-field/appended-field.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NgxSearchComponent } from 'src/app/shared/ngx-search/ngx-search.component';

/**
 * Component for append fields section.
 */
@Component({
  selector: 'app-append-action',
  templateUrl: './append-action.component.html',
  styleUrls: ['./append-action.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    LoadingIndicatorComponent,
    MatDividerModule,
    AppendedFieldComponent,
    MatSlideToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxSearchComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AppendActionComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AppendActionComponent),
      multi: true,
    },
  ],
})
export class AppendActionComponent implements OnInit, OnDestroy {
  /** Subject that emits when the component has been destroyed. */
  protected _destroyed$ = new Subject<void>();

  /** Helper for conditions. */
  conditionHelper = ConditionHelper;

  /** Questions to filter. */
  questionsToFilter: Question[] = [];

  /** Filtered questions for base field. */
  filteredQuestions: Question[] = [];

  /** Bucket Questions. */
  _bucketQuestions: Question[] = [];

  /** Bucket Questions. */
  @Input() set bucketQuestions(bucketQuestions: Question[]) {
    this._bucketQuestions = bucketQuestions;
    this.filteredQuestions = [];
    const containerInfoOnBucket = lodash.uniqBy(
      bucketQuestions.filter(
        (q) => QuestionFieldType.ContainerName === q.questionType,
      ),
      'questionType',
    );
    this.filteredQuestions = [
      ...bucketQuestions.filter(({ questionType }) =>
        [
          QuestionFieldType.LongText,
          QuestionFieldType.ShortText,
          QuestionFieldType.Select,
          QuestionFieldType.RadioList,
          QuestionFieldType.CheckList,
          QuestionFieldType.MultiSelect,
        ].includes(questionType),
      ),
      ...containerInfoOnBucket,
    ];
  }

  /**
   * Disable or not add action.
   * @returns An array of filtered questions.
   */
  get bucketQuestions(): Question[] {
    return this._bucketQuestions;
  }

  /** Contains the action of the power that will be edited. */
  @Input() action: PowerAction | null = null;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Whether you are saving the current action . */
  @Input() savingAction = false;

  /** Confirms whether the action is being edited. */
  @Input() editingAction = false;

  /** Whether you are deleting the current action in parent. */
  @Input() deletingAction = false;

  /** Emit Cancel the new action. */
  @Output() cancelAppendField = new EventEmitter<void>();

  /** Emit the new action to add it into the list of actions in the power. */
  @Output() actionEmitter = new EventEmitter<PowerAction>();

  /** Emit the current action to delete it. */
  @Output() actionToRemove = new EventEmitter<PowerAction>();

  /** Form for append field action. */
  formAppendFieldAction = new FormGroup({
    target: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, this.notInQuestionsValidator()],
    }),
    source: new FormGroup({
      values: new FormControl<string[]>([]),
      includeSpacing: new FormControl<boolean>(false),
    }),
  });

  /** Action type selector value. */
  actionTypeValue: ActionType | '' = '';

  /** The selected base question. */
  selectedBaseField: Question | null = null;

  /** Contains the values to be added to the base field. */
  appendFieldValues: string[] = [''];

  /** Current text which user is typing . */
  currentValueInSelect = '';

  /** Current field index which user is typing . */
  currentIndexInSelect = -1;

  /** Whether the custom value field show or not. */
  isCustomField = true;

  /** Field validate for any content. */
  customValid = false;

  /** Field selected is short or long text. */
  isTextField = false;

  /** The current questions based on the Append Value. */
  currentAppendValueQuestionList: Question[] = [];

  /**
   * Init lifecycle.
   */
  ngOnInit(): void {
    this.checkDisableAutoComplete();
    if (this.action) {
      this.setAppendFieldToEdit();
    }
    this.targetControlChanges$();
  }

  /**
   * Disable or not add action.
   * @returns A boolean.
   */
  get disableAddField(): boolean {
    return (
      (this.appendFieldValues.some((field) => lodash.isEmpty(field)) &&
        this.formAppendFieldAction.controls.source.controls.includeSpacing
          .value) ||
      !this.customValid ||
      (!this.appendFieldValues.lastIndexOf(' ') && this.isCustomField)
    );
  }

  /**
   * Get the list of questions for the append value.
   * @returns Questions for the append value options.
   */
  get appendValueQuestionList(): Question[] {
    let arrayFiltered: Question[] = [];
    let containerInfoOnBucket: Question[] = [];

    switch (this.selectedBaseField?.questionType) {
      case QuestionFieldType.ContainerName:
      case QuestionFieldType.ShortText:
      case QuestionFieldType.LongText:
        if (
          this.selectedBaseField.questionType !==
          QuestionFieldType.ContainerName
        ) {
          arrayFiltered = this.conditionHelper.filterTextFields(
            this.selectedBaseField?.questionType,
            this.bucketQuestions,
          ) as Question[];
        } else {
          arrayFiltered = ActionHelper.getAppendFieldsForContainerName(
            this.bucketQuestions,
          ) as Question[];
        }

        containerInfoOnBucket = lodash.uniqBy(
          arrayFiltered.filter((q) =>
            StationTerms.ContainerInfoItemsAsQuestion.some(
              ({ questionType }) => questionType === q.questionType,
            ),
          ),
          'questionType',
        );

        arrayFiltered = [
          ...arrayFiltered.filter(
            (q) =>
              !StationTerms.ContainerInfoItemsAsQuestion.find(
                (ques) => ques.questionType === q.questionType,
              ) && !ActionHelper.isAddressQuestion(q),
          ),
          ...containerInfoOnBucket,
        ];
        break;

      case QuestionFieldType.Select:
      case QuestionFieldType.RadioList:
      case QuestionFieldType.MultiSelect:
      case QuestionFieldType.CheckList:
        arrayFiltered = this.conditionHelper.selectListAvailableQuestions(
          this.bucketQuestions,
        );
        break;
    }

    //We will exclude the first selected field from all the elements.
    const arrUniq = lodash.uniqBy(
      arrayFiltered.filter(
        (q) => q.rithmId !== this.selectedBaseField?.rithmId,
      ),
      'rithmId',
    );

    return arrUniq;
  }

  /**
   * Subscribe for target form control changes.
   */
  private targetControlChanges$(): void {
    this.formAppendFieldAction.controls.target.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((questionId) => {
        this.setTextFieldSelectedFlag(questionId);
      });
  }

  /** Check whether or not you should disable autocomplete.*/
  checkDisableAutoComplete(): void {
    if (!this.filteredQuestions.length) {
      this.formAppendFieldAction.controls.target.disable();
    }
  }

  /**
   * Show the prompt for the selected question.
   * @param value The current question.
   * @returns Question prompt.
   */
  displayPromptSelected = (value: string): string => {
    const question = this.filteredQuestions.find(
      ({ rithmId }) => rithmId === value,
    );
    if (question) {
      this.formAppendFieldAction.controls.source.controls.values.enable();
      this.selectedBaseField = question;
      this.currentAppendValueQuestionList = this.appendValueQuestionList;
    }
    return question ? question.prompt : '';
  };

  /** Put the values of the added field to edit it.*/
  setAppendFieldToEdit(): void {
    if (this.action) {
      const data = JSON.parse(this.action.data) as AppendActionData;
      const targetQuestion = this.bucketQuestions.some(
        (question) => question.rithmId === data.target,
      );
      !targetQuestion && (data.target = '');
      this.formAppendFieldAction.setValue(data);
      this.appendFieldValues = data.source.values;
      this.customValid = this.appendFieldValues.length > 0;
      this.setTextFieldSelectedFlag(data.target);
      this.displayPromptSelected(data.target);
    }
  }

  /**
   * Edit the selected value from the list of fields to add.
   * @param value The new value.
   * @param index The position of the value changed.
   */
  editValue(value: string, index: number): void {
    this.customValid = value.length > 0;
    this.currentValueInSelect = '';
    if (value.trim()) {
      this.appendFieldValues[index] = value.trim();
      this.formAppendFieldAction.controls.source.controls.values.setValue(
        this.appendFieldValues,
      );
    }
  }

  /**
   * Reset the selected value from the list of fields to add.
   * @param index The position of the value changed.
   */
  resetAppend(index: number): void {
    this.appendFieldValues[index] = '';
  }

  /**
   * Check the empty value for the custom value, if including space enabled or not.
   * @returns A boolean.
   */
  validateEmpty(): boolean {
    return (
      this.formAppendFieldAction.controls.source.controls.includeSpacing
        .value === false && this.isCustomField
    );
  }

  /**
   * Validate empty value.
   * @param value The new value.
   * @param index The position of the value changed.
   */
  validateEmptyValue(value: string, index: number): void {
    // Empty space for adding in the custom field for content.
    this.customValid = value.length > 0;
    const whiteSpace = /\s/g.test(value);
    if (whiteSpace) {
      value.replace(/\s+/g, ' ');
    }
    this.currentValueInSelect = value;
    this.currentIndexInSelect = index;
    //If value is empty, reset select
    if (!value.trim()) {
      this.appendFieldValues[index] = this.validateEmpty()
        ? value
        : value.trim();
    }
  }

  /**
   * Removes one of the values from the list of fields to add.
   * @param value The new value.
   * @param index The position of the valor a remover.
   */
  removeValue(value: string, index: number): void {
    this.customValid = this.appendFieldValues.length > 1;
    this.appendFieldValues.splice(index, 1);
    this.formAppendFieldAction.controls.source.controls.values.setValue(
      this.appendFieldValues,
    );
  }

  /**
   * It is added in the new field to be added.
   */
  addAppendField(): void {
    this.customValid = false;
    /** The new position is added in the related arrays. */
    this.appendFieldValues.push('');
    /** The values in the form will be updated. */
    this.formAppendFieldAction.controls.source.controls.values.setValue(
      this.appendFieldValues,
    );
  }

  /**
   * Saving action type changes.
   *
   */
  public addActionToPower(): void {
    const formData = this.formAppendFieldAction.getRawValue();
    // Check the last item white space and if removed.
    const lastIndex = formData.source.values?.lastIndexOf(' ');
    if (
      formData &&
      lastIndex &&
      lastIndex + 1 === formData.source.values?.length
    ) {
      formData.source.values?.splice(lastIndex, 1);
    }
    if (this.action) {
      this.action.target = this.formAppendFieldAction.controls.target.value;
      this.action.data = JSON.stringify(formData);
    }
    const appendAction: PowerAction = this.action || {
      order: 1,
      rithmId: uuidv4(),
      type: ActionType.AppendField,
      target: this.formAppendFieldAction.controls.target.value,
      data: JSON.stringify(formData),
      resultMapping: '',
      header: '',
    };
    this.actionEmitter.emit(appendAction);
  }

  /**
   * Is the custom for the appended values or not.
   * @param event Whether or not there is custom value.
   */
  isCustomValueEvent(event: boolean): void {
    this.isCustomField = event;
  }

  /**
   * Cancel the process of Append Field.
   */
  onCancelAppendField(): void {
    this.cancelAppendField.emit();
  }

  /**
   * Remove the action from the parent.
   */
  removeParentAction(): void {
    this.action && this.actionToRemove.emit(this.action);
  }

  /**
   * Selected target field is text field or not.
   * @param questionId Question rithmId of the target question.
   */
  private setTextFieldSelectedFlag(questionId: string): void {
    this.isTextField = this.filteredQuestions.some(
      ({ questionType, rithmId }) =>
        rithmId === questionId &&
        [QuestionFieldType.ShortText, QuestionFieldType.LongText].includes(
          questionType,
        ),
    );
  }

  /**
   * Check if this value is among the question options.
   * @returns A validator function.
   */
  private notInQuestionsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = this.filteredQuestions.some(
        (question) => question.rithmId === control.value,
      );
      return !isValid ? { notInQuestions: { value: control.value } } : null;
    };
  }

  /**
   * Cleanup method.
   */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
