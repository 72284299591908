<div class="flex flex-col w-full max-w-md py-4">
  @if (orderOfOperations) {
    <p class="text-base font-medium">ChatGPT</p>
  } @else {
    <p class="title-from-order-operation">Action: ChatGPT</p>
  }

  <div class="flex flex-col mt-4" [ngClass]="{ 'gap-4': !orderOfOperations }">
    <div class="flex flex-col">
      @if (orderOfOperations) {
        <p class="text-xs sub-section-title">Prompt field:</p>
      } @else {
        <p class="text-primary-400 text-md leading-tight tracking-wide">
          Prompt field:
        </p>
      }
      <section class="chips-section flex flex-col">
        <mat-chip-set>
          <mat-chip class="flex items-center">
            @if (orderOfOperations) {
              @if (!isCustomAction && promptText !== '--') {
                <i class="fak fa-textarea text-gray-600 pr-1"></i>
              }
              <span class="text-primary-500 text-sm label-chip">
                {{ promptText }}
              </span>
            } @else {
              <span> {{ promptText }}</span>
            }
          </mat-chip>
        </mat-chip-set>
      </section>
    </div>
    <div class="flex flex-col" [ngClass]="{ 'mt-2': orderOfOperations }">
      @if (orderOfOperations) {
        <p class="text-xs sub-section-title">Response field:</p>
      } @else {
        <p class="text-primary-400 text-md leading-tight tracking-wide">
          Response field:
        </p>
      }
      <section class="chips-section flex flex-col">
        <mat-chip-set>
          <mat-chip class="flex items-center">
            @if (orderOfOperations) {
              @if (responseText !== '--') {
                <i class="fak fa-textarea text-gray-600 pr-1"></i>
              }
              <span class="text-primary-500 text-sm label-chip">
                {{ responseText }}
              </span>
            } @else {
              <span> {{ responseText }}</span>
            }
          </mat-chip>
        </mat-chip-set>
      </section>
    </div>
  </div>
</div>
