@if (!isLoading && !isLoadingStations() && !isError) {
  <div class="flex flex-col w-full max-w-md">
    <p
      [ngClass]="
        orderOfOperations ? 'action-title' : 'title-from-order-operation'
      "
    >
      @if (!orderOfOperations) {
        Action:
      }
      Advanced Update Field
    </p>
    <div class="flex flex-col gap-2 mt-4">
      <section class="updating-section">
        <div class="flex flex-col">
          <p class="sub-section-title tracking-wide">Updating:</p>
          <section
            class="chips-section flex"
            [ngClass]="orderOfOperations ? 'flex-row space-x-2' : 'flex-col'"
          >
            <!-- Stations selected -->
            <mat-chip-set>
              @for (
                station of advancedUpdateFieldData.targetStations;
                track $index
              ) {
                <mat-chip class="flex items-center">
                  @if (orderOfOperations) {
                    <i class="fak fa-station icon-chip"></i>
                    <span class="label-chip">
                      {{ getStationName(station) || station }}
                    </span>
                  } @else {
                    <p class="max-w-[300px] truncate">
                      {{ termsGeneric.Station.Single }}:
                      {{ getStationName(station) || station }}
                    </p>
                  }
                </mat-chip>
              }
            </mat-chip-set>
            <!-- Field selected -->
            <mat-chip-set>
              <mat-chip class="flex items-center truncate max-w-[300px]">
                @if (orderOfOperations) {
                  <i
                    class="icon-chip"
                    [ngClass]="
                      getQuestionTypeIcon(advancedUpdateFieldData.field)
                    "
                  ></i>
                  <span class="label-chip">
                    {{ getQuestionName(advancedUpdateFieldData.field) }}
                  </span>
                } @else {
                  <p>
                    Field: {{ getQuestionName(advancedUpdateFieldData.field) }}
                  </p>
                }
              </mat-chip>
            </mat-chip-set>
          </section>
        </div>
      </section>
      <section class="updates-to-section">
        <div class="flex flex-col">
          <p class="sub-section-title tracking-wide">Updates to (source):</p>
          <section
            class="chips-section flex"
            [ngClass]="orderOfOperations ? 'flex-row space-x-2' : 'flex-col'"
          >
            <!-- Stations selected -->
            <mat-chip-set>
              @for (
                station of advancedUpdateFieldData.sourceStations;
                track $index
              ) {
                <mat-chip class="flex items-center">
                  @if (orderOfOperations) {
                    <i class="fak fa-station icon-chip"></i>
                    <span class="label-chip">
                      {{ getStationName(station) }}
                    </span>
                  } @else {
                    <p class="max-w-[300px] truncate">
                      {{ termsGeneric.Station.Single }}:
                      {{ getStationName(station) }}
                    </p>
                  }
                </mat-chip>
              }
            </mat-chip-set>
            <!-- Field selected -->
            <mat-chip-set>
              <mat-chip class="flex items-center">
                @if (orderOfOperations) {
                  @if (
                    getQuestionTypeIcon(advancedUpdateFieldData.sourceValue)
                  ) {
                    <i
                      class="icon-chip"
                      [ngClass]="
                        getQuestionTypeIcon(advancedUpdateFieldData.sourceValue)
                      "
                    ></i>
                  }
                  <span class="label-chip">
                    {{ getCustomName(advancedUpdateFieldData.sourceValue) }}
                  </span>
                } @else {
                  <p class="max-w-[300px] truncate">
                    {{ getCustomName(advancedUpdateFieldData.sourceValue) }}
                  </p>
                }
              </mat-chip>
            </mat-chip-set>
          </section>
        </div>
      </section>
      <section class="updates-to-section">
        <div class="flex flex-col">
          <p class="sub-section-title tracking-wide">When (Matching Fields):</p>
          <mat-chip-set>
            @for (
              field of advancedUpdateFieldData.matchingFields;
              track $index
            ) {
              <mat-chip class="flex items-center">
                @if (orderOfOperations) {
                  <i
                    class="icon-chip"
                    [ngClass]="getQuestionTypeIcon(field)"
                  ></i>
                  <span class="label-chip">
                    {{ getQuestionName(field) }}
                  </span>
                } @else {
                  <p class="truncate max-w-[300px]">
                    Field: {{ getQuestionName(field) }}
                  </p>
                }
              </mat-chip>
            }
          </mat-chip-set>
        </div>
      </section>
    </div>
  </div>
}

@if (isError) {
  <div
    id="failed-organization"
    class="w-full h-full flex flex-col items-center justify-center text-center mt-2 md:mt-0"
  >
    <button
      mat-stroked-button
      type="button"
      color="warn"
      (click)="ngOnInit()"
      id="try-again"
      data-testid="try-again"
      class="w-32"
    >
      Try Again
    </button>
    <p class="text-error-500 pt-2">Error: Unable to show this Rule.</p>
  </div>
}

@if (isLoading || isLoadingStations()) {
  <div class="w-full flex min-h-[250px] justify-center items-center pt-2">
    <app-loading-indicator inlineText="Loading" />
  </div>
}
