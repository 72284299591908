@if (!isStation) {
  <div class="grid grid-cols-3 py-0 bg-secondary-400">
    <div
      class="text-white"
      [ngClass]="{
        'col-start-2 text-center': title,
        'h-10 ': !title
      }"
    >
      <h6 class="leading-10">{{ title }}</h6>
    </div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="newSubHeader"></ng-container>
}

<ng-template #newSubHeader>
  <div [ngClass]="{ 'border-b border-gray-200': isStation }" class="h-[98px]">
    @if (isStation) {
      <section>
        <mat-tab-group
          #tabGroup
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          [selectedIndex]="indexTab"
          class="tabs-rithm"
          (selectedIndexChange)="tabSelectedChanged(tabGroup)"
        >
          <mat-tab label="GRID" aria-label="GRID">
            <ng-template mat-tab-label>
              <i class="text-xl fal fa-box mr-2 text-gray-400 icon-tab"></i
              ><span data-testid="Container-tab" class="label-tab">Grid</span>
            </ng-template>
          </mat-tab>
          @if (isArchitect || isWorkerOrOwner) {
            <mat-tab
              label="Rules"
              aria-label="Rules"
              [disabled]="nonEditableStation"
            >
              <ng-template mat-tab-label>
                <i
                  class="text-xl fal fa-clipboard-list-check mr-2 text-gray-400 icon-tab"
                ></i
                ><span data-testid="Rules-tab" class="label-tab">Rules</span>
              </ng-template>
            </mat-tab>
            <mat-tab
              label="Issues"
              aria-label="Issues"
              [disabled]="nonEditableStation"
            >
              <ng-template mat-tab-label>
                <i
                  class="text-xl fal fa-triangle-exclamation mr-2 text-gray-400 icon-tab"
                ></i
                ><span data-testid="Issues-tab" class="label-tab">Issues</span>
              </ng-template>
            </mat-tab>
            <mat-tab
              [label]="termsGeneric.Container.Plural"
              [aria-label]="termsGeneric.Container.Plural"
              [disabled]="nonEditableStation"
            >
              <ng-template mat-tab-label>
                <i class="text-xl fal fa-boxes mr-2 text-gray-400 icon-tab"></i>
                <span class="label-tab">{{
                  termsGeneric.Container.Plural
                }}</span>
              </ng-template>
            </mat-tab>
            <mat-tab
              label="Trash"
              aria-label="Trash"
              [disabled]="nonEditableStation"
            >
              <ng-template mat-tab-label>
                <i class="text-xl fal fa-trash mr-2 text-gray-400 icon-tab"></i>
                <span class="label-tab">Trash</span>
              </ng-template>
            </mat-tab>
          }
        </mat-tab-group>
      </section>
    }

    <section
      class="w-full h-[50px] md:px-2 flex justify-between items-center bg-white border-b-[1px] border-gray-300 overflow-auto scrollbar"
    >
      <!-- TOOLBAR SECTION -->

      <section class="w-[115px]">
        @if (editMode() && indexTab === 0) {
          <div class="flex h-full items-center">
            <button
              type="button"
              id="add-frame-button"
              data-testid="add-frame-button"
              class="p-2 hover:bg-secondary-50 gridster-toolbar-button"
              (click)="addInputFrame(widgetType.InputWidget)"
              matTooltip="Add Input Frame"
            >
              <i class="fa-light fa-frame text-primary-500"></i>
            </button>
            <button
              type="button"
              id="add-text-component-button"
              data-testid="add-text-component-button"
              class="p-2 hover:bg-secondary-50 gridster-toolbar-button"
              (click)="addInputFrame(widgetType.BodyWidget)"
              matTooltip="Add Text Box"
            >
              <i class="fa-light fa-text text-primary-500"></i>
            </button>
            <button
              type="button"
              id="add-image-widget"
              data-testid="add-image-widget"
              class="p-2 hover:bg-secondary-50 gridster-toolbar-button"
              (click)="addInputFrame(widgetType.ImageWidget)"
              matTooltip="Add Image"
            >
              <i class="fa-light fa-image"></i>
            </button>

            @if (
              stationGrid &&
              stationGrid.layoutMode &&
              stationGrid.widgetFocused > -1
            ) {
              <button
                type="button"
                id="button-remove-widget"
                data-testid="button-remove-widget"
                class="p-2 gridster-toolbar-button"
                (click)="stationGrid.removeWidgetsHandler()"
                [disabled]="
                  stationGrid.widgetFocused < 0 ||
                  stationGrid.isLoadingPowerQuestion
                "
                [ngClass]="{
                  'animate-pulse': stationGrid.isLoadingPowerQuestion,
                  'hover:bg-secondary-50': stationGrid.widgetFocused >= 0
                }"
              >
                <span
                  class="fa-light fa-trash text-secondary-200"
                  [ngClass]="{
                    'text-red-500': stationGrid.widgetFocused > -1,
                    'text-secondary-200': stationGrid.widgetFocused < 0
                  }"
                ></span>
              </button>
            }
          </div>
        }
      </section>

      @if (itemInfo) {
        <section class="display-ruby h-full">
          <div class="flex items-center h-full">
            <h6
              class="name-plates"
              [matTooltip]="stationName"
              matTooltipPosition="left"
              appRightClickMenu
              [link]="
                '/' +
                termsGeneric.Station.Single.toLowerCase() +
                '/' +
                stationRithmId
              "
              [enableRightClick]="!isArchitect"
            >
              {{ stationName.toString() | titlecase }}
            </h6>
            <mat-divider [vertical]="true" style="height: 32px" />
            @if (containerLabel.length) {
              <h6
                class="name-plates"
                [matTooltip]="
                  containerLabel.length
                    ? containerLabel
                    : ('Untitled Container' | titlecase)
                "
                matTooltipPosition="right"
              >
                {{
                  containerLabel.length
                    ? containerLabel
                    : ('Untitled Container' | titlecase)
                }}
              </h6>
            } @else {
              <ng-container *ngTemplateOutlet="containerLabelLoading" />
            }

            <ng-template #containerLabelLoading>
              <app-loading-indicator
                [inlineText]="'Loading label'"
                class="ml-2"
              />
            </ng-template>
          </div>
        </section>

        <div class="h-full flex items-center">
          @if (isArchitect) {
            <button
              type="button"
              [ngClass]="{ active: activeItem === 'stationInfo' }"
              data-testid="station-info-button"
              id="station-info-button"
              mat-icon-button
              [disabled]="isDrawerOpen || !itemInfo"
              [matTooltip]="'Open information drawer'"
              matTooltipPosition="left"
              [matMenuTriggerFor]="drawerMenu"
            >
              <i
                class="fa-light fa-info-circle text-lg"
                [ngClass]="
                  isDrawerOpen ? 'text-secondary-100' : 'text-gray-800'
                "
              ></i>
            </button>
          }
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="namesLoading" />
      }

      <ng-template #namesLoading>
        <div class="mt-2 w-full flex justify-center">
          <app-loading-indicator [inlineText]="' '" />
        </div>
      </ng-template>
    </section>
  </div>
</ng-template>

<mat-menu
  #drawerMenu="matMenu"
  id="element-drawer"
  hasBackdrop
  class="w-80"
  yPosition="below"
>
  <button mat-menu-item type="button" (click)="toggleDrawer('stationInfo')">
    {{ termsGeneric.Station.Single }} info
  </button>
  <button mat-menu-item type="button" (click)="toggleDrawer('containerInfo')">
    {{ termsGeneric.Container.Single }} info
  </button>
</mat-menu>
